import { BaseController, Kuzzle } from 'kuzzle-sdk';

class TelegramController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'telegram');
  }

  async sendCode(phoneNumber: string): Promise<{
    message: string;
    timeout: number;
  }> {
    const response = await this.query({
      action: 'sendCode',
      body: {
        phoneNumber,
      },
    });
    return response.result;
  }

  async resendCode() {
    const response = await this.query({
      action: 'resendCode',
    });
    return response.result;
  }

  async signIn(phoneCode: string) {
    const response = await this.query({
      action: 'signIn',
      body: {
        phoneCode,
      },
    });
    return response.result;
  }

  async logOut() {
    const response = await this.query({
      action: 'logOut',
    });
    return response.result;
  }

  async getAllChats():Promise<FetchedSignalProvider[]> {
    const response = await this.query({
      action: 'getAllChats',
    });
    return response.result;
  }

  async subscribeToProvider(provider_id: string) {
    const response = await this.query({
      action: 'subscribeToProvider',
      body: {
        provider_id,
      },
    });
    return response.result;
  }

  async unsubscribeFromProvider(providerId: string) {
    const response = await this.query({
      action: 'unsubscribeFromProvider',
      body: {
        providerId,
      },
    });
    return response.result;
  }
}

export default TelegramController;
