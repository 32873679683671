const kuzzle = {
  host: process.env.NEXT_PUBLIC_KUZZLE_HOST || 'localhost',
  port: parseInt(process.env.NEXT_PUBLIC_KUZZLE_PORT as string, 10) || 7512,
  ssl: process.env.NEXT_PUBLIC_KUZZLE_SSL === 'true',
  adminToken: process.env.KUZZLE_ADMIN_API_TOKEN,
};

const NODE_ENV = process.env.NODE_ENV || 'development';

const dev = !['production', 'staging'].includes(NODE_ENV);

const cookies = {
  jwt: 'x-gryzle',
};

const database = {
  index: `gryzle-${NODE_ENV}`,
  pageConfigIndex: `gryzle-website-${NODE_ENV}`,
};

const minio = {
  endpoint: process.env.NEXT_PUBLIC_MINIO_ENDPOINT || 'localhost',
  buckets: {
    private: `gryzle-private-${NODE_ENV}`,
    public: `gryzle-public-${NODE_ENV}`,
  },
};

const growthbook = {
  apiUrl: process.env.NEXT_PUBLIC_GROWTHBOOK_API_URL,
};

const mixpanel = {
  token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
  apiHost: process.env.NEXT_PUBLIC_MIXPANEL_API_URL,
};

const unlayer = {
  projectId: process.env.NEXT_PUBLIC_UNLAYER_PROJECT_ID,
};

const variables = {
  NODE_ENV,
  kuzzle,
  dev,
  cookies,
  database,
  minio,
  growthbook,
  mixpanel,
  unlayer,
};

export default variables;
