import { BaseController, Kuzzle } from 'kuzzle-sdk';

export default class MessagesController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'manager');
  }

  public async sendMessage(to: string, content: string) {
    const result = await this.query({
      action: 'sendMessage',
      body: {
        to,
        content,
      },
    });
    return result.result;
  }

  public async getConversation(kuid: string) {
    const result = await this.query({
      action: 'getConversation',
      body: {
        kuid,
      },
    });
    return result.result;
  }

  public async listConversations(
    from: number = 0,
    size: number = 10,
  ) {
    const result = await this.query({
      action: 'listConversations',
      body: {
        from,
        size,
      },
    });
    return result.result;
  }
}
