import { BaseController, Kuzzle } from 'kuzzle-sdk';

class SignalController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'signal');
  }

  async list(
    from: number = 0,
    size: number = 10,
  ): Promise<GryzleSignal[]> {
    const response = await this.query({
      action: 'list',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }
}

export default SignalController;
