import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from 'store/reducers/app/app.slice';
import kuzzle from 'services/kuzzle';
import { getJwt, removeJwt, saveJwt } from 'utils/cookies';
import { rawUser } from 'utils/raw';
import { mixpanel, growthbook } from 'services/analytics';

export const logoutUser = createAsyncThunk(
  'user/logout',
  async (payload, { getState }) => {
    kuzzle.jwt = getJwt();
    const { kuzzle: { roomIds } } = ((getState() as any).app) as AppState;
    await Promise.all(roomIds.map((id) => kuzzle.realtime.unsubscribe(id)));
    await kuzzle.auth.logout();
    removeJwt();
    mixpanel.track('user_logout');
  },
);

export const fetchUser = createAsyncThunk<GryzleUser>(
  'user/fetchUser',
  async () => {
    kuzzle.jwt = getJwt();
    const res = await kuzzle.auth.getCurrentUser();
    const user = {
      _id: res._id,
      ...res.serialize()._source,
      roles: res.profileIds,
    } as GryzleUser;
    return user;
  },
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (user: Partial<Omit<GryzleUser, '_id'>>, { dispatch }) => {
    kuzzle.jwt = getJwt();
    await kuzzle.auth.updateSelf(user);
    mixpanel.track('user_profile_update');
    await dispatch(fetchUser());
  },
);

export const requestEmailVerification = createAsyncThunk(
  'user/requestEmailVerification',
  async (payload, { getState, dispatch }) => {
    const user = (getState() as any).user as GryzleUser;
    if (user.email_verified) return;
    kuzzle.jwt = getJwt();
    await kuzzle.email.requestEmailVerification();
    mixpanel.track('user_email_verification_request');
    await dispatch(fetchUser()).unwrap();
  },
);

export const confirmEmailVerification = createAsyncThunk(
  'user/verifyEmail',
  async (payload: string, { getState, dispatch }) => {
    const user = (getState() as any).user as GryzleUser;
    if (user.email_verified) return;
    kuzzle.jwt = getJwt();
    await kuzzle.email.confirmEmailVerification(payload);
    mixpanel.track('user_email_verification_confirm');
    await dispatch(fetchUser()).unwrap();
  },
);

export const registerUser = createAsyncThunk(
  'user/register',
  async (values: { username: string; password: string; email: string }, { dispatch }) => {
    const isAuthenticated = await kuzzle.isAuthenticated();
    if (isAuthenticated) {
      await kuzzle.auth.logout();
    }
    await kuzzle.security.createRestrictedUser({
      content: { ...rawUser, email: values.email },
      credentials: {
        local: {
          username: values.username,
          password: values.password,
        },
      },
    });
    await kuzzle.auth.login('local', { username: values.username, password: values.password }, '7d');
    await saveJwt();
    const { _id } = await dispatch(fetchUser()).unwrap();
    mixpanel.track('user_registration');
    mixpanel.identify(_id);
    growthbook.setAttributes({
      id: _id,
    });
  },
);

export const loginUser = createAsyncThunk(
  'user/login',
  async (payload: { username: string; password: string }, { dispatch }) => {
    kuzzle.jwt = getJwt();
    const isAuthenticated = await kuzzle.isAuthenticated();
    if (isAuthenticated) {
      await kuzzle.auth.logout();
    }
    await kuzzle.auth.login('local', payload, '7d');
    await saveJwt();
    mixpanel.track('user_login');
    const { _id } = await dispatch(fetchUser()).unwrap();
    mixpanel.identify(_id);
    growthbook.setAttributes({
      id: _id,
    });
  },
);

export const fetchNotifications = createAsyncThunk(
  'user/fetchNotifications',
  async (): Promise<UserNotification[]> => {
    kuzzle.jwt = getJwt();
    const notifications = await kuzzle.notification.getAllNotifications();
    return notifications;
  },
);

export const markAllNotificationAsRead = createAsyncThunk(
  'user/markAllNotificationAsRead',
  async () => {
    kuzzle.jwt = getJwt();
    await kuzzle.notification.markAllAsRead();
  },
);

export const fetchSignals = createAsyncThunk(
  'user/fetchSignals',
  async (
    param?: { from?: number; size?: number; },
  ): Promise<GryzleSignal[]> => {
    kuzzle.jwt = getJwt();
    const res = await kuzzle.signal.list(param?.from, param?.size);
    return res;
  },
);

export const fetchNews = createAsyncThunk(
  'user/fetchNews',
  async (
    param?: { from?: number; size?: number; },
  ): Promise<News[]> => {
    kuzzle.jwt = getJwt();
    const res = await kuzzle.marketaux.getNews(param?.from, param?.size);
    return res;
  },
);

export const populateNews = createAsyncThunk(
  'user/populateNews',
  async () => {
    kuzzle.jwt = getJwt();
    const res = await kuzzle.marketaux.populateNews();
    return res;
  },
);
