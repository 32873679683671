const rawUser = {
  firstname: '',
  lastname: '',
  email_verified: false,
  onboarded: false,
  email: '',
  avatar: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    zip: '',
    country: '',
  },
  notifications: {
    email: [],
    sms: [],
    push: [],
  },
  birthdate: null,
  stripe_customer_id: '',
  subscriptions: [],
  telegram: null,
};

export { rawUser };
