import { Kuzzle, BaseController } from 'kuzzle-sdk';

export default class CustomerController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'customer');
  }

  public async applyToBeProvider(body: any) {
    const response = await this.query({
      action: 'applyToBeProvider',
      body,
    });
    return response.result;
  }

  public async subscribeToProvider(id: string) {
    const response = await this.query({
      action: 'subscribeToProvider',
      body: { id },
    });
    return response.result;
  }

  public async listProviders(
    from: number = 0,
    size: number = 10,
  ) {
    const response = await this.query({
      action: 'listProviders',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }
}
