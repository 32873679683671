import { BaseController, Kuzzle } from 'kuzzle-sdk';

export default class ProviderController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'provider');
  }

  public async becomeCustomer() {
    const response = await this.query({
      action: 'becomeCustomer',
    });
    return response.result;
  }

  public async addCustomer(data: any) {
    const response = await this.query({
      action: 'addCustomer',
      body: data,
    });
    return response.result;
  }

  public async deleteCustomer(id: string) {
    const response = await this.query({
      action: 'deleteCustomer',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async getCustomer(id: string) {
    const response = await this.query({
      action: 'getCustomer',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async listCustomers(
    from: number = 0,
    size: number = 10,
  ) {
    const response = await this.query({
      action: 'listCustomers',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }

  public async upgradeCustomer(id: string, plan: string) {
    const response = await this.query({
      action: 'upgradeCustomer',
      body: {
        id,
        plan,
      },
    });
    return response.result;
  }

  public async createSignal(data: any) {
    const response = await this.query({
      action: 'createSignal',
      body: data,
    });
    return response.result;
  }

  public async updateSignalEta(id: string, data: any) {
    const response = await this.query({
      action: 'updateSignalEta',
      body: {
        id,
        data,
      },
    });
    return response.result;
  }

  public async createSubscriptionPlan(data: any) {
    const response = await this.query({
      action: 'createSubscriptionPlan',
      body: data,
    });
    return response.result;
  }

  public async deleteSubscriptionPlan(id: string) {
    const response = await this.query({
      action: 'deleteSubscriptionPlan',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async listSubscriptionPlan(
    from: number = 0,
    size: number = 10,
  ) {
    const response = await this.query({
      action: 'listSubscriptionPlan',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }
}
