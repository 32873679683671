import { GrowthBook } from '@growthbook/growthbook-react';
import variables from 'config/variables';
import mixpanel from 'mixpanel-browser';

// Create a GrowthBook instance
const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    mixpanel.track('growthbook.experiment_viewed', {
      experiment_id: experiment.key,
      variation_id: result.variationId,
      $source: 'growthbook',
    });
  },
});

mixpanel.init(
  variables.mixpanel.token as string,
  {
    debug: variables.dev,
    api_host: variables.mixpanel.apiHost,
    loaded: (mp) => {
      growthbook.setAttributes({
        ...growthbook.getAttributes(),
        id: mp.get_distinct_id(),
      });
    },
  },
);

export const loadGrowthbookFeatures = async () => {
  const raw = await fetch(variables.growthbook.apiUrl as string);
  const json = await raw.json();
  growthbook.setFeatures(json.features);
};

export { growthbook, mixpanel };
