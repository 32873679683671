import { createAsyncThunk } from '@reduxjs/toolkit';
import kuzzle from 'services/kuzzle';
import { getJwt } from 'utils/cookies';

const fetchChats = createAsyncThunk(
  'telegram/fetchChats',
  async () => {
    kuzzle.jwt = getJwt();
    const result = await kuzzle.telegram.getAllChats();
    const sortedWithKnownFirst = result.sort((x, y) => (
      (x.known === y.known) ? 0 : x.known ? -1 : 1
    ));
    return sortedWithKnownFirst;
  },
);

export { fetchChats };
