import { BaseController, Kuzzle } from 'kuzzle-sdk';

export default class SupportController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'support');
  }

  public async createSupportTicket(data: any) {
    const response = await this.query({
      action: 'createSupportTicket',
      body: data,
    });
    return response.result;
  }

  public async getSupportTicket(id: any) {
    const response = await this.query({
      action: 'getSupportTicket',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async listSupportTickets(
    from: number = 0,
    size: number = 10,
  ) {
    const response = await this.query({
      action: 'listSupportTickets',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }

  public async closeSupportTicket(id: string) {
    const response = await this.query({
      action: 'closeSupportTicket',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async reopenSupportTicket(id: string) {
    const response = await this.query({
      action: 'reopenSupportTicket',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async updateSupportTicket(id: string, data: any) {
    const response = await this.query({
      action: 'updateSupportTicket',
      body: {
        id,
        data,
      },
    });
    return response.result;
  }
}
