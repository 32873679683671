import { BaseController, Kuzzle } from 'kuzzle-sdk';

class MinioController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'minio');
  }

  async presignedUrl(data: { isPrivate: boolean; fileKey: string; method: 'GET' | 'POST' | 'PUT' }): Promise<string> {
    const response = await this.query({
      action: 'presignedUrl',
      body: data,
    });
    return response.result;
  }

  async deleteObjects(data: { isPrivate: boolean; fileKeys: string[] }): Promise<string> {
    const response = await this.query({
      action: 'deleteObjects',
      body: data,
    });
    return response.result;
  }
}

export default MinioController;
