import { Kuzzle, WebSocket } from 'kuzzle-sdk';
import variables from 'config/variables';
import CoursesController from './controllers/courses';
import CustomerController from './controllers/customer';
import EmailController from './controllers/email';
import ManagerController from './controllers/manager';
import MarketauxController from './controllers/marketaux';
import MessagesController from './controllers/messages';
import MinioController from './controllers/minio';
import NotificationController from './controllers/notification';
import ProviderController from './controllers/provider';
import SignalController from './controllers/signal';
import StatsController from './controllers/stats';
import SupportController from './controllers/support';
import TelegramController from './controllers/telegram';
import AppInfo from '../../../package.json';

const originalKuzzle = new Kuzzle(
  new WebSocket(
    variables.kuzzle.host,
    {
      ssl: variables.kuzzle.ssl,
      port: variables.kuzzle.port,
      autoReconnect: true,
    },
  ),
  {
    autoQueue: true,
    offlineMode: 'auto',
    autoReplay: true,
    volatile: {
      app: {
        name: 'gryzle-web-app',
        version: AppInfo.version,
      },
    },
  },
);

originalKuzzle.useController(CoursesController, 'courses');
originalKuzzle.useController(CustomerController, 'customer');
originalKuzzle.useController(EmailController, 'email');
originalKuzzle.useController(ManagerController, 'manager');
originalKuzzle.useController(MarketauxController, 'marketaux');
originalKuzzle.useController(MessagesController, 'messages');
originalKuzzle.useController(MinioController, 'minio');
originalKuzzle.useController(NotificationController, 'notification');
originalKuzzle.useController(ProviderController, 'provider');
originalKuzzle.useController(SignalController, 'signal');
originalKuzzle.useController(StatsController, 'stats');
originalKuzzle.useController(SupportController, 'support');
originalKuzzle.useController(TelegramController, 'telegram');

originalKuzzle.connect();

// @ts-expect-error kuzzle doesn't extend the type internally
const kuzzle: typeof originalKuzzle & {
  courses: CoursesController;
  customer: CustomerController;
  email: EmailController;
  manager: ManagerController;
  marketaux: MarketauxController;
  messages: MessagesController;
  minio: MinioController;
  notification: NotificationController;
  provider: ProviderController;
  signal: SignalController;
  stats: StatsController;
  support: SupportController;
  telegram: TelegramController;
} = originalKuzzle;

if (variables.dev && typeof window !== 'undefined') {
  // @ts-expect-error Custom property to debug in navigator
  window.kuzzle = kuzzle;
}

export default kuzzle;
