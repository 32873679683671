import { BaseController, Kuzzle } from 'kuzzle-sdk';

class EmailController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'email');
  }

  async resetPassword(token: string, password: string) {
    const response = await this.query({
      controller: 'kuzzle-plugin-auth-passport-local/password',
      action: 'reset',
      body: {
        token,
        password,
      },
    });
    return response.result;
  }

  async requestPasswordReset(email: string) {
    const response = await this.query({
      action: 'requestPasswordReset',
      body: {
        email,
      },
    });
    return response.result;
  }

  async requestEmailVerification() {
    const response = await this.query({
      action: 'requestEmailVerification',
    });
    return response.result;
  }

  async confirmEmailVerification(token: string) {
    const response = await this.query({
      action: 'confirmEmailVerification',
      body: {
        token,
      },
    });
    return response.result;
  }
}

export default EmailController;
