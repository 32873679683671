import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { } from './app.thunk';

interface InitialState {
  kuzzle: {
    roomIds: string[];
    isConnectedToServer: boolean;
  }
}

const initialState: InitialState = {
  kuzzle: {
    roomIds: [],
    isConnectedToServer: true,
  },
};

const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    reset: (state: InitialState) => Object.assign(state, initialState),
    addKuzzleRoomId: (state: InitialState, action: PayloadAction<string>) => {
      if (!state.kuzzle.roomIds.includes(action.payload)) {
        state.kuzzle.roomIds.push(action.payload);
      }
    },
    removeKuzzleRoomId: (state: InitialState, action: PayloadAction<string>) => {
      state.kuzzle.roomIds = state.kuzzle.roomIds.filter((id) => id !== action.payload);
    },
    setKuzzleIsConnectedToServer: (state: InitialState, action: PayloadAction<boolean>) => {
      state.kuzzle.isConnectedToServer = action.payload;
    },
  },
});

export default AppSlice;
export { initialState };
export const {
  reset,
  addKuzzleRoomId,
  removeKuzzleRoomId,
  setKuzzleIsConnectedToServer,
} = AppSlice.actions;
export type AppState = ReturnType<typeof AppSlice.reducer>;
