import { DefaultSeoProps } from 'next-seo';

const defaultProps: DefaultSeoProps = {
  defaultTitle: 'Gryzle',
  titleTemplate: '%s | Gryzle',
  description: 'Gryzle is an copy trading platform for signal providers and traders. Gryzle automatically copies trades of signal providers and provides educational material for traders to learn from their experiences.',
  openGraph: {
    type: 'website',
    url: 'https://gryzle.com',
    title: 'Gryzle Copy Trading platform',
    site_name: 'Gryzle',
    description: 'Gryzle is an copy trading platform for signal providers and traders. Gryzle automatically copies trades of signal providers and provides educational material for traders to learn from their experiences.',
    images: [
      {
        url: 'https://gryzle.com/static/images/og-image.png',
        width: 1200,
        height: 630,
        alt: 'Gryzle Logo',
      },
    ],
  },
  twitter: {
    handle: '@itishermann',
    cardType: 'app',
    site: '@GryzleHQ',
  },
};

export default defaultProps;
