import { configureStore, combineReducers, Middleware } from '@reduxjs/toolkit';
import {
  PersistConfig,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
  createMigrate,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userSlice from './reducers/user/user.slice';
import telegramSlice from './reducers/telegram/telegram.slice';
import appSlice from './reducers/app/app.slice';
import manifest from './migration';

const persistConfig: PersistConfig<any> = {
  key: 'gryzle-data',
  storage,
  whitelist: [
    'user',
  ],
  blacklist: [],
  version: 0,
  migrate: createMigrate(manifest, {
    debug: process.env.NODE_ENV === 'development',
  }),
};

const middleware: Middleware[] = [];

const rootReducer = combineReducers({
  [appSlice.name]: appSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [telegramSlice.name]: telegramSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(...middleware),
});

const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export { store, persistor };
