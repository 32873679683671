import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { fetchUser, loginUser, logoutUser } from './user.thunk';

type InitialState = GryzleUser;
const initialState: InitialState = {
  _id: '',
  address: {
    city: '',
    country: '',
    line1: '',
    line2: '',
    zip: '',
  },
  email: '',
  email_verified: false,
  firstname: '',
  lastname: '',
  notifications: {
    email: [],
    push: [],
    sms: [],
  },
  stripe_customer_id: '',
  subscriptions: [],
  avatar: '',
  onboarded: false,
  birthdate: dayjs().unix(),
  telegram: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state: InitialState) => Object.assign(state, initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        Object.assign(state, payload);
      })
      .addCase(logoutUser.fulfilled, (state, { error }) => {
        Object.assign(state, initialState);
      });
  },
});

export default userSlice;
export { initialState };
export type UserState = ReturnType<typeof userSlice.reducer>;
