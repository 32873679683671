import { BaseController, Kuzzle } from 'kuzzle-sdk';

export default class MarketauxController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'marketaux');
  }

  async getNews(
    from: number = 0,
    size: number = 10,
  ): Promise<News[]> {
    const response = await this.query({
      action: 'get',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }

  async populateNews(): Promise<string> {
    const response = await this.query({
      action: 'populate',
    });
    return response.result;
  }
}
