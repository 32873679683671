import { BaseController, Kuzzle } from 'kuzzle-sdk';

export default class CoursesController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'courses');
  }

  public async listCourses(
    from: number = 0,
    size: number = 10,
  ) {
    const response = await this.query({
      action: 'listCourses',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }

  public async getCourse(id: string) {
    const response = await this.query({
      action: 'getCourse',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async createCourse(body: any) {
    const response = await this.query({
      action: 'createCourse',
      body,
    });
    return response.result;
  }

  public async updateCourse(id: string, body: any) {
    const response = await this.query({
      action: 'updateCourse',
      body,
    });
    return response.result;
  }

  public async deleteCourse(id: string) {
    const response = await this.query({
      action: 'deleteCourse',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async purchaseCourse(id: string) {
    const response = await this.query({
      action: 'purchaseCourse',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async rateCourse(id: string, rating: number) {
    const response = await this.query({
      action: 'rateCourse',
      body: {
        id,
        rating,
      },
    });
    return response.result;
  }
}
