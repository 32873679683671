import { AppProps } from 'next/app';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import PlausibleProvider from 'next-plausible';
import { SpotlightProvider } from '@mantine/spotlight';
import { NotificationsProvider } from '@mantine/notifications';
import theme from 'config/theme';
import extendDayJs from 'utils/extendDayJs';
import React, { useEffect } from 'react';
import { persistor, store } from 'store';
import Loader from 'components/Loader';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import kuzzle from 'services/kuzzle';
import { setKuzzleIsConnectedToServer } from 'store/reducers/app/app.slice';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbook, loadGrowthbookFeatures } from 'services/analytics';
import { DefaultSeo, LogoJsonLd } from 'next-seo';
import RouterTransition from 'components/RouterTransition';
import SEO from '../../next-seo.config';

extendDayJs();

export default function App(props: AppProps) {
  const { Component, pageProps } = props;

  useEffect(() => {
    loadGrowthbookFeatures();
    ['reconnected', 'connected'].forEach(
      (event) => kuzzle.on(event, () => store.dispatch(setKuzzleIsConnectedToServer(true))),
    );
    ['disconnected', 'networkError'].forEach(
      (event) => kuzzle.on(event, () => store.dispatch(setKuzzleIsConnectedToServer(false))),
    );
  }, []);

  return (
    <PlausibleProvider
      domain="gryzle.com"
      customDomain="https://plausible.itishermann.me"
      trackOutboundLinks
      selfHosted
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={theme}
      >
        <ModalsProvider>
          <NotificationsProvider position="top-right">
            <SpotlightProvider actions={[]}>
              <Provider store={store}>
                <PersistGate loading={<Loader />} persistor={persistor}>
                  <GrowthBookProvider growthbook={growthbook}>
                    <DefaultSeo {...SEO} />
                    <LogoJsonLd
                      logo="https://gryzle.com/static/images/logo-image.png"
                      url="http://gryzle.com"
                    />
                    <RouterTransition />
                    <Component {...pageProps} />
                  </GrowthBookProvider>
                </PersistGate>
              </Provider>
            </SpotlightProvider>
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </PlausibleProvider>
  );
}
