import { BaseController, Kuzzle } from 'kuzzle-sdk';

class NotificationController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'notification');
  }

  async markAllAsRead(): Promise<string> {
    const response = await this.query({
      action: 'markAllAsRead',
    });
    return response.result;
  }

  async getAllNotifications(): Promise<UserNotification[]> {
    const response = await this.query({
      action: 'getAllNotifications',
    });
    return response.result;
  }
}

export default NotificationController;
