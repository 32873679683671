import variables from 'config/variables';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import kuzzle from 'services/kuzzle';
import dayjs from 'dayjs';

const saveJwt = async () => {
  const jwtInfos = await kuzzle.auth.checkToken();
  setCookie(
    variables.cookies.jwt,
    kuzzle.jwt,
    {
      expires: dayjs(jwtInfos.expiresAt).toDate(),
      sameSite: 'strict',
      secure: !variables.dev,
    },
  );
};

const removeJwt = () => {
  deleteCookie(variables.cookies.jwt);
};

const getJwt = () => getCookie(variables.cookies.jwt);

export { saveJwt, removeJwt, getJwt };
