import {
  createStyles, Container, Center, Loader as Progress,
} from '@mantine/core';
import React from 'react';
import GryzleLogo from './GryzleLogo';

const useStyles = createStyles(() => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },
  inner: {
    position: 'relative',
  },
}));

interface LoaderProps {
  withLogo?: boolean;
}

const Loader = ({ withLogo = false }: LoaderProps) => {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <Center>
          {withLogo && <GryzleLogo height={42} />}
          <Progress size="xl" />
        </Center>
      </div>
    </Container>
  );
};

export default Loader;
