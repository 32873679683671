import {
  BaseController, KDocument, KDocumentContent, Kuzzle,
} from 'kuzzle-sdk';

export default class ManagerController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'manager');
  }

  public async createCustomer(body: any) {
    const response = await this.query({
      action: 'createCustomer',
      body,
    });
    return response.result;
  }

  public async deleteCustomer(id: string) {
    const response = await this.query({
      action: 'deleteCustomer',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async getCustomer(id: string) {
    const response = await this.query({
      action: 'getCustomer',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async listCustomers(
    from: number = 0,
    size: number = 10,
  ) {
    const response = await this.query({
      action: 'listCustomers',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }

  public async updateCustomer(id: string, data: any) {
    const result = await this.query({
      action: 'updateCustomer',
      body: {
        id,
        data,
      },
    });
    return result.result;
  }

  public async createProvider(body: any) {
    const response = await this.query({
      action: 'createProvider',
      body,
    });
    return response.result;
  }

  public async deleteProvider(id: string) {
    const response = await this.query({
      action: 'deleteProvider',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async getProvider(id:string) {
    const response = await this.query({
      action: 'getProvider',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async listProviders(
    from: number = 0,
    size: number = 10,
  ) {
    const response = await this.query({
      action: 'listProviders',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }

  public async updateProvider(id: string, data: any) {
    const response = await this.query({
      action: 'updateProvider',
      body: {
        id,
        data,
      },
    });
    return response.result;
  }

  public async approveProvider(id:string) {
    const response = await this.query({
      action: 'approveProvider',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async listArticles(
    from: number = 0,
    size: number = 10,
  ) {
    const response = await this.query({
      action: 'listArticles',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }

  public async listSentEmails(
    from: number = 0,
    size: number = 10,
  ) {
    const response = await this.query({
      action: 'listSentEmails',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }

  public async createArticle(body: any) {
    const response = await this.query({
      action: 'createArticle',
      body,
    });
    return response.result;
  }

  public async updateArticle(id: string, data: any) {
    const response = await this.query({
      action: 'updateArticle',
      body: {
        id,
        data,
      },
    });
    return response.result;
  }

  public async deleteArticle(id:string) {
    const result = await this.query({
      action: 'deleteArticle',
      body: {
        id,
      },
    });
    return result.result;
  }

  public async getArticle(id:string) {
    const response = await this.query({
      action: 'getArticle',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async createEmailTemplate(body: Omit<EmailTemplate, 'id' | 'author_id'> & { template_id?: string }): Promise<KDocument<EmailTemplate & KDocumentContent>> {
    const response = await this.query({
      action: 'createEmailTemplate',
      body,
    });
    return response.result;
  }

  public async updateEmailTemplate(body: Omit<EmailTemplate, 'id' | 'author_id'> & { id: string }) {
    const response = await this.query({
      action: 'updateEmailTemplate',
      body,
    });
    return response.result;
  }

  public async deleteEmailTemplate(id:string) {
    const response = await this.query({
      action: 'deleteEmailTemplate',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async getEmailTemplate(id:string): Promise<EmailTemplate> {
    const response = await this.query({
      action: 'getEmailTemplate',
      body: {
        id,
      },
    });
    return response.result;
  }

  public async listEmailTemplates(
    from: number = 0,
    size: number = 10,
  ): Promise<EmailTemplate[]> {
    const response = await this.query({
      action: 'listEmailTemplates',
      body: {
        from,
        size,
      },
    });
    return response.result;
  }
}
