import { BaseController, Kuzzle } from 'kuzzle-sdk';

export default class StatsController extends BaseController {
  constructor(kuzzle: Kuzzle) {
    super(kuzzle, 'stats');
  }

  public async getCustomerStats(): Promise<CustomerStat> {
    const result = await this.query({
      action: 'getCustomerStats',
    });
    return result.result;
  }

  public async getProviderStats(): Promise<ProviderStat> {
    const result = await this.query({
      action: 'getProviderStats',
    });
    return result.result;
  }

  public async getManagerStats(): Promise<ManagerStat> {
    const result = await this.query({
      action: 'getManagerStats',
    });
    return result.result;
  }
}
