import { createSlice } from '@reduxjs/toolkit';
import { fetchChats } from './telegram.thunk';

interface InitialState {
  chats: Array<FetchedSignalProvider>;
}

const initialState: InitialState = {
  chats: [],
};

const telegramSlice = createSlice({
  name: 'telegram',
  initialState,
  reducers: {
    reset: (state: InitialState) => Object.assign(state, initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChats.fulfilled, (state, { payload }) => {
      state.chats = payload;
    });
  },
});

export default telegramSlice;
export { initialState };
export type TelegramState = ReturnType<typeof telegramSlice.reducer>;
